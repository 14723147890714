<template>
    <div>
        <HeroSection class="py-8" />
        <RateProviders class="py-8" />
        <UnlockReturnsSection class="py-8" />
        <ThreeStepProcess class="py-8" />
        <RateComparisonForm class="py-8" />
        <AboutSection class="py-8" />
    </div>
</template>

<script>
import HeroSection from '../components/HeroSection.vue';
import RateProviders from '../components/RateProviders.vue';
import UnlockReturnsSection from '../components/UnlockReturnsSection.vue';
import ThreeStepProcess from '../components/ThreeStepProcess.vue';
import RateComparisonForm from '../components/RateComparisonForm.vue';
import AboutSection from '../components/AboutSection.vue';

export default {
    components: {
        HeroSection,
        RateProviders,
        UnlockReturnsSection,
        ThreeStepProcess,
        RateComparisonForm,
        AboutSection
    }
}
</script>
