<template>
    <div class="container mx-auto px-4 py-16">
        <h1 class="text-5xl font-bold mb-12 text-white">Terms and Conditions</h1>

        <div class="bg-white p-8 rounded-lg shadow-md">
            <section v-for="(item, index) in termsContent" :key="index"
                class="mb-10 border-b pb-8 last:border-b-0 last:pb-0">
                <h2 class="text-3xl font-semibold mb-4 text-blue-600">{{ toRoman(index + 1) }}. {{ item.title }}</h2>
                <p class="text-lg leading-relaxed pl-6">{{ item.content }}</p>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsAndConditions',
    data() {
        return {
            termsContent: [
                {
                    title: 'Introduction',
                    content: 'These terms and conditions govern your use of our website. By using our website, you accept these terms and conditions in full. If you disagree with any part of these terms and conditions, do not use our website.'
                },
                {
                    title: 'License to Use Website',
                    content: 'Unless otherwise stated, we own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.'
                },
                {
                    title: 'Acceptable Use',
                    content: 'You must not use our website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent, or harmful.'
                },
                {
                    title: 'Restricted Access',
                    content: 'Access to certain areas of our website is restricted. We reserve the right to restrict access to other areas of our website, or indeed our whole website, at our discretion.'
                },
                {
                    title: 'User Content',
                    content: 'Your user content must not be illegal or unlawful, must not infringe any third party\'s legal rights, and must not be capable of giving rise to legal action whether against you or us or a third party.'
                },
                {
                    title: 'No Warranties',
                    content: 'This website is provided “as is” without any representations or warranties, express or implied. We make no representations or warranties in relation to this website or the information and materials provided on this website.'
                },
                {
                    title: 'Limitations and Exclusions of Liability',
                    content: 'To the extent that the website and the information on the website is provided, we will not be liable for any loss or damage of any nature.'
                },
                {
                    title: 'Indemnity',
                    content: 'You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities, and expenses incurred or suffered by us arising out of any breach by you of any provision of these terms and conditions.'
                },
                {
                    title: 'Breaches of These Terms and Conditions',
                    content: 'Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, we may take such action as we deem appropriate to deal with the breach.'
                },
                {
                    title: 'Variation',
                    content: 'We may revise these terms and conditions from time to time. Revised terms and conditions will apply to the use of our website from the date of the publication of the revised terms and conditions on our website.'
                },
                {
                    title: 'Entire Agreement',
                    content: 'These terms and conditions, together with our privacy policy, constitute the entire agreement between you and us in relation to your use of our website and supersede all previous agreements in respect of your use of this website.'
                },
                {
                    title: 'Law and Jurisdiction',
                    content: 'These terms and conditions will be governed by and construed in accordance with Canadian law, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of Canada.'
                }
            ]
        }
    },
    methods: {
        toRoman(num) {
            const roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };
            let str = '';

            for (let i of Object.keys(roman)) {
                let q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        }
    }
}
</script>

<style scoped>
/* Additional custom styles if needed */

/* Remove the bottom border for the last term */
.last:border-b-0 {
    border-bottom: none !important;
}
</style>
