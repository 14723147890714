import { createApp } from "vue";
import App from "./App.vue";
import router from './router.js';
import VueGtag from "vue-gtag";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import './assets/styles.css';

// Import Firebase configuration and Firestore instance
import { db } from "./firebase"; // Import from firebase.js

// FontAwesome Imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCoffee, faUser, faEye, faBalanceScale, faLightbulb } from '@fortawesome/free-solid-svg-icons';

// Add FontAwesome icons to the library for use
library.add(faCoffee, faUser, faEye, faBalanceScale, faLightbulb);

const appInstance = createApp(App);

// Register the FontAwesome icon component globally
appInstance.component('font-awesome-icon', FontAwesomeIcon);

// Use vue-tel-input
appInstance.use(VueTelInput);

// Provide the Firestore instance for all components
appInstance.provide('db', db);

// Use the router with the main app
appInstance.use(router);

// Use vue-gtag with the main app
appInstance.use(VueGtag, {
    config: {
        id: "GTM-5RR8ZK5C" // Ensure you use the correct ID here
    }
});

appInstance.mount('#app');
