<template>
    <div class="container mx-auto px-4 py-16">
        <PrivacyPolicyComponent />
    </div>
</template>

<script>
import PrivacyPolicyComponent from '@/components/PrivacyPolicyComponent.vue';

export default {
    name: 'PrivacyPolicyView',
    components: {
        PrivacyPolicyComponent
    }
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
