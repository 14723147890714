<template>
    <div class="bg-gray-50 py-16">
        <!-- Container -->
        <div class="container mx-auto px-4">
            <div class="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">

                <!-- Text content -->
                <div class="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:pr-8">
                    <h2 class="text-4xl font-extrabold mb-8 text-blue-600">
                        Fixed Rate Finder
                    </h2>

                    <h3 class="text-2xl font-semibold mb-6">
                        About Us
                    </h3>

                    <p class="text-lg mb-6 leading-relaxed">
                        In the ever-evolving financial landscape, discerning Canadian investors are always on the quest for
                        dependable avenues to amplify their wealth. Enter Fixed Rate Finder, a beacon for those who seek
                        the
                        pinnacle of fixed income investments in Canada. Our commitment? To offer unmatched returns without
                        ever
                        compromising on the security of your investments.
                    </p>

                    <p class="text-lg leading-relaxed">
                        But there's more to us than just our name. At Fixed Rate Finder, we pride ourselves on our
                        unparalleled access to premier fixed income rates. We're not just a platform; we're a promise. Our
                        seasoned experts tirelessly track, analyze, and curate data, ensuring that you're always armed with
                        the
                        most current and comprehensive investment information.
                    </p>
                </div>

                <!-- Image -->
                <div class="w-full md:w-1/2 flex justify-center md:pl-8">
                    <img src="@/assets/97b9d9e5-roundpig.png" alt="Piggy Bank Image"
                        class="h-56 w-56 md:h-96 md:w-96 object-cover rounded-full shadow-2xl transform hover:scale-105 transition-transform duration-300">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutSection'
}
</script>

<style scoped>
/* Additional styles if needed, but mostly it will be handled by Tailwind classes */
</style>
