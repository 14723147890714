<template>
    <div class="bg-white py-16">
        <!-- Container -->
        <div class="container mx-auto px-4 text-center">
            <h2 class="text-3xl font-extrabold mb-6 text-blue-600">
                A Simple, Straightforward 3-Step Journey
            </h2>
            <p class="text-xl mb-12">
                Dive into Canada's Best Rates: Seamlessly Compare GICs, Government Bonds, and Term Deposits with
                FIXED-INTEREST.BOND!
            </p>

            <!-- Steps -->
            <div class="flex flex-wrap -mx-4 justify-center">
                <!-- Step 1 -->
                <div class="flex flex-col w-full md:w-1/3 px-4 mb-8 md:mb-0">
                    <div
                        class="flex-grow rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 flex flex-col">
                        <img src="@/assets/0e452f81-53_10d908h0c508g000001028.png" alt="Step 1 Image"
                            class="w-full h-64 object-cover">
                        <div class="p-6">
                            <div class="text-blue-600 text-5xl font-bold mb-4">1</div>
                            <p>Tap into our free tool and find providers offering Canada's leading rates for GICs, Term
                                Deposits, and Government Bonds.</p>
                        </div>
                    </div>
                </div>

                <!-- Step 2 -->
                <div class="flex flex-col w-full md:w-1/3 px-4 mb-8 md:mb-0">
                    <div
                        class="flex-grow rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 flex flex-col">
                        <img src="@/assets/b38d6afe-45_10ek04i000000000000028.png" alt="Step 2 Image"
                            class="w-full h-64 object-cover">
                        <div class="p-6">
                            <div class="text-blue-600 text-5xl font-bold mb-4">2</div>
                            <p>Engage with a dedicated Canadian advisor. Receive insights tailored to your needs and get
                                answers from industry experts.</p>
                        </div>
                    </div>
                </div>

                <!-- Step 3 -->
                <div class="flex flex-col w-full md:w-1/3 px-4 mb-8 md:mb-0">
                    <div
                        class="flex-grow rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 flex flex-col">
                        <img src="@/assets/ef1f12aa-3_10hc0ba0dj09600g00g028.png" alt="Step 3 Image"
                            class="w-full h-64 object-cover">
                        <div class="p-6">
                            <div class="text-blue-600 text-5xl font-bold mb-4">3</div>
                            <p>Commit to your best-fit investment. Experience a seamless, hassle-free start with our trusted
                                partners.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ThreeStepProcess'
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
