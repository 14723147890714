import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyApvj0Ov_kMAJr83Uh7ToPsaMrAMeiEalQ",
    authDomain: "leads-db-30e73.firebaseapp.com",
    projectId: "leads-db-30e73",
    storageBucket: "leads-db-30e73.appspot.com",
    messagingSenderId: "700075315735",
    appId: "1:700075315735:web:3ce74de3085f3905b2c252",
    measurementId: "G-0F2DBDKYVS"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Initialize Cloud Firestore
const db = getFirestore();

// Export firestore instance for use elsewhere
export { db };
