<template>
    <div class="container mx-auto px-4 py-16">
        <h1 class="text-5xl font-bold mb-12 text-white">Privacy Policy</h1>

        <div class="bg-white p-8 rounded-lg shadow-md">
            <section v-for="(item, index) in privacyContent" :key="index"
                class="mb-10 border-b pb-8 last:border-b-0 last:pb-0">
                <h2 class="text-3xl font-semibold mb-4 text-blue-600">{{ item.title }}</h2>
                <p class="text-lg leading-relaxed pl-6">{{ item.content }}</p>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicyComponent',
    data() {
        return {
            privacyContent: [
                {
                    title: 'Introduction',
                    content: 'Our company respects your privacy and is committed to protecting your personal data. This Privacy Policy will inform you about how we handle and protect your personal data, your rights, and how the law protects you.'
                },
                {
                    title: 'Data We Collect',
                    content: 'We collect personal data you provide to us. This includes information you give us (like your name, address, email address, and phone number), data about your operations, and data from other entities within our group.'
                },
                {
                    title: 'How We Use Your Data',
                    content: 'We use your data to process and manage your requests, process payments, prevent fraud, and personalize your experience on our platform.'
                },
                {
                    title: 'Disclosure of Data',
                    content: 'We may share your personal data with trusted third parties. This is necessary to provide our services and for our legitimate interests.'
                },
                {
                    title: 'Data Security',
                    content: 'We have put in place security measures to prevent your personal data from being accessed, altered, disclosed, or accidentally lost. Access to your personal data is limited to those employees and partners who have a business need to know such data. They will process your personal data only upon our instructions and are subject to a duty of confidentiality.'
                },
                {
                    title: 'Data Retention',
                    content: 'We will only retain your personal data for as long as necessary to fulfill the purposes for which we collected it. This includes satisfying any legal, accounting, or reporting requirements.'
                },
                {
                    title: 'Your Legal Rights',
                    content: 'Under certain circumstances, you have rights under data protection laws in relation to your personal data. You may have the right to request access, correction, erasure, or restriction of your personal data.'
                },
                {
                    title: 'Cookies',
                    content: 'We use cookies to distinguish you from other users of our website. This helps us provide a good experience when you browse our website and allows us to improve our platform.'
                },
                {
                    title: 'Changes to the Policy',
                    content: 'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. It is advisable to review this Privacy Policy periodically for any changes.'
                }
            ]
        }
    }
}
</script>

<style scoped>
/* Additional custom styles if needed */

/* Remove the bottom border for the last section */
.last:border-b-0 {
    border-bottom: none !important;
}
</style>
