<template>
    <div id="rateComparisonForm" class="bg-gray-50 py-16">
        <!-- Container -->
        <div class="container mx-auto px-4 text-center">
            <h2 class="text-3xl font-extrabold mb-6 text-blue-600">
                Elevate Your Financial Horizon with Premium Returns
            </h2>
            <p class="text-lg mb-4 text-gray-700">
                Embark on an informed investment journey. Enrol today and gain access to our exclusive <span
                    class="text-blue-600">"Award-Winning Investors Guide: The Canadian's Guide To Fixed Interest
                    Bonds"</span>. Delve into meticulously researched insights, navigate your options with precision, and
                leverage the expertise of finance's leading minds.
            </p>

            <!-- Form -->
            <div class="bg-white rounded-lg shadow-md p-8 max-w-4xl mx-auto">

                <!-- Success and Error Messages Here -->
                <div v-if="successMessage"
                    class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
                    {{ successMessage }}
                </div>
                <div v-if="errorMessage"
                    class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                    {{ errorMessage }}
                </div>

                <form @submit.prevent="handleSubmit">
                    <div class="flex flex-wrap -mx-4">
                        <!-- Name -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="name" class="block text-sm font-medium text-gray-700">Name *</label>
                            <input type="text" id="name" name="name" v-model="formData.name" required
                                class="mt-1 p-2 w-full border rounded-md">
                        </div>

                        <!-- Phone using vue-tel-input -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label class="block text-sm font-medium text-gray-700">Phone *</label>
                            <vue-tel-input v-model="formData.phone" :preferredCountries="['ca']" :required="true"
                                mode="international" :input-options="{ showDialCode: true }"
                                :invalidMsg="'Please enter a valid phone number.'" class="mt-1 w-full"
                                @validate="handleValidation" @country-changed="handleCountryChange">
                            </vue-tel-input>
                        </div>

                        <!-- Email -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="email" class="block text-sm font-medium text-gray-700">Email *</label>
                            <input type="email" id="email" name="email" v-model="formData.email" required
                                class="mt-1 p-2 w-full border rounded-md">
                        </div>

                        <!-- Province -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="province" class="block text-sm font-medium text-gray-700">Province *</label>
                            <select id="province" name="province" v-model="formData.province" required
                                class="mt-1 p-2 w-full border rounded-md">
                                <option value="Alberta">Alberta</option>
                                <option value="British Columbia">British Columbia</option>
                                <option value="Manitoba">Manitoba</option>
                                <option value="New Brunswick">New Brunswick</option>
                                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                <option value="Northwest Territories">Northwest Territories</option>
                                <option value="Nova Scotia">Nova Scotia</option>
                                <option value="Nunavut">Nunavut</option>
                                <option value="Ontario">Ontario</option>
                                <option value="Prince Edward Island">Prince Edward Island</option>
                                <option value="Quebec">Quebec</option>
                                <option value="Saskatchewan">Saskatchewan</option>
                                <option value="Yukon">Yukon</option>
                            </select>
                        </div>

                        <!-- Preferred Investment -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="preferredInvestment" class="block text-sm font-medium text-gray-700">Preferred
                                Investment</label>
                            <select id="preferredInvestment" name="preferredInvestment"
                                v-model="formData.preferredInvestment" required class="mt-1 p-2 w-full border rounded-md">
                                <option value="gic_per_annum">GIC PER ANNUM</option>
                                <option value="government_bond">GOVERNMENT BOND</option>
                                <option value="top_5_bank_bond">TOP 5 BANK BOND</option>
                                <option value="other">All</option>
                            </select>
                        </div>

                        <!-- Investment Amount -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="investmentAmount" class="block text-sm font-medium text-gray-700">Investment
                                Amount</label>
                            <select id="investmentAmount" name="investmentAmount" v-model="formData.investmentAmount"
                                required class="mt-1 p-2 w-full border rounded-md">
                                <option value="25000_to_50000">$25,000 to $50,000</option>
                                <option value="50000_to_100000">$50,000 to $100,000</option>
                                <option value="100000_to_250000">$100,000 to $250,000</option>
                                <option value="250000_to_500000">$250,000 to $500,000</option>
                                <option value="500000_plus">$500,000 and above</option>
                            </select>
                        </div>


                        <!-- Investment Timing -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="investmentTiming" class="block text-sm font-medium text-gray-700">When are you
                                looking to invest?</label>
                            <select id="investmentTiming" name="investmentTiming" v-model="formData.investmentTiming"
                                required class="mt-1 p-2 w-full border rounded-md">
                                <option value="now">I'm ready to invest now</option>
                                <option value="couple_weeks">I'm ready to invest in a couple of weeks</option>
                                <option value="within_months">I plan to invest within a few months</option>
                                <option value="gathering_info">I'm just gathering information</option>
                                <option value="not_sure">I'm not sure</option>
                            </select>
                        </div>

                        <!-- Term -->
                        <div class="w-full md:w-1/2 px-4 mb-4">
                            <label for="term" class="block text-sm font-medium text-gray-700">What term are you looking
                                for?</label>
                            <select id="term" name="term" v-model="formData.term" required
                                class="mt-1 p-2 w-full border rounded-md">
                                <option value="6_months">6 MONTHS</option>
                                <option value="1_year">1 YEAR</option>
                                <option value="2_years">2 YEARS</option>
                                <option value="3_years_plus">3 YEARS +</option>
                            </select>
                        </div>

                    </div>

                    <div class="mt-8">
                        <button type="submit"
                            class="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full transition duration-300">
                            COMPARE NOW
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
/* global grecaptcha */
/* eslint-disable no-unused-vars */

import { event } from 'vue-gtag';
import { getFirestore, addDoc, collection } from "firebase/firestore"; // Modified the import to match the working version
import { VueTelInput } from 'vue-tel-input';

export default {
    name: 'RateComparisonForm',
    components: {
        VueTelInput
    },
    data() {
        return {
            formData: {
                name: '',
                phone: '',
                email: '',
                province: '',
                preferredInvestment: '',
                investmentAmount: '',
                investmentTiming: '',
                term: '',
                recaptchaToken: ''
            },
            errorMessage: '',
            successMessage: '',
            isSubmitting: false,
            isPhoneValid: null,  // Phone validation
            recaptchaSiteKey: '6LeOAicoAAAAAB1js04i0tdsKoYdhyJHEA5uigUo'  // replace with your actual site key
        };
    },
    methods: {
        isValidEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        },
        handleValidation(phoneObject) {
            if (phoneObject && typeof phoneObject.isValid !== "undefined") {
                this.isPhoneValid = phoneObject.isValid;
            }
        },
        handleCountryChange(country) {
            console.log("Selected country:", country);
        },
        async handleSubmit(event) {
            this.isSubmitting = true; // Set to true at the beginning

            console.log("handleSubmit started");
            event.preventDefault();
            console.log("Event default prevented");

            // Logging form data
            console.log("Form Data:", this.formData);

            // Ensure phone has valid format
            if (this.formData.phone && this.formData.phone.dialCode && this.formData.phone.number) {
                this.formData.phone = '+' + this.formData.phone.dialCode + this.formData.phone.number;
            }

            // Validate email format
            if (!this.isValidEmail(this.formData.email)) {
                this.errorMessage = 'Please enter a valid email address.';
                console.log("Email validation failed");
                return;
            }
            console.log("Email validation passed");

            // Validate phone format
            if (this.isPhoneValid === false) {
                this.errorMessage = 'Please enter a valid phone number.';
                console.log("Phone validation failed");
                return;
            }
            console.log("Phone validation passed");

            // Get reCAPTCHA token with enhanced error handling
            try {
                console.log("Attempting to get reCAPTCHA token");
                const token = await grecaptcha.execute(this.recaptchaSiteKey, {});
                this.formData.recaptchaToken = token;  // Storing the token in formData
                console.log("reCAPTCHA token obtained successfully");
            } catch (error) {
                console.error('Error obtaining reCAPTCHA token:', error);
                this.errorMessage = 'There was an issue with reCAPTCHA validation. Please try again.';
                return;
            }

            // Track the form submission event with vue-gtag
            this.$gtag.event('form_submission', {
                event_category: 'Form',
                event_label: 'RateComparisonForm',
                value: 'Form Submitted'
            });

            console.log("Form submission event tracked");

            // Firestore interaction with enhanced error handling
            try {
                console.log("Attempting to submit form data to Firestore");
                this.formData.timestamp = new Date().toISOString();

                // Initialize Firestore within the method
                const db = getFirestore();

                await addDoc(collection(db, 'CanadianFixedInterestBondSubmissions'), this.formData);

                console.log("Form data submitted to Firestore successfully");
                this.successMessage = 'Congratulations! You\'ve taken the first step to elevate your financial horizon with premium returns. You will soon gain access to our exclusive \'Award-Winning Investors Guide: The Canadian\'s Guide To Fixed Interest Bonds\'. Dive into meticulously researched insights and navigate your options with precision. An advisor will be in touch with you shortly.';
            } catch (error) {
                console.error('Error submitting form:', error);
                this.errorMessage = 'We apologize for the inconvenience, but there seems to be an issue processing your request. At Fixed Rate Finder, we are committed to offering unmatched returns and ensuring the security of your investments. Please try again later or reach out to our dedicated team for assistance.';
            } finally {
                this.isSubmitting = false;  // Set to false at the end
                console.log("Form submission process completed");
            }
        }

    }
}
</script>

<style scoped>
/* [styles from the first code go here] */
</style>
