<template>
    <div class="bg-gradient-to-r from-gray-100 to-gray-200 py-20">
        <!-- Container -->
        <div class="container mx-auto px-4 text-center mb-12">

            <!-- Title Section -->
            <h2 class="text-4xl font-extrabold mb-4 text-blue-600">Discover Canada's Premier Investment Guide</h2>
            <p class="text-2xl mb-2">Crafted by the nation's top financial minds.</p>
            <p class="text-xl mb-2">Empower your financial journey. Secure tomorrow.</p>
            <p class="text-xl mb-6">Invest with clarity and unmatched insight.</p>
            <div class="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-6">
                <span class="text-2xl mb-2 sm:mb-0">🔍</span>
                <p class="text-lg text-blue-500 font-bold">Fixed Rate Finder:</p>
            </div>

        </div>

        <!-- Rate Provider Cards -->
        <div class="container mx-auto px-4">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                <!-- Card Template -->
                <div v-for="provider in providers" :key="provider.name"
                    class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1 border-l-8 border-blue-600 flex flex-col justify-between">
                    <h2 class="text-2xl font-semibold mb-4">{{ provider.name }}</h2>
                    <p class="mb-4">{{ provider.gicPerAnnum }}</p>
                    <p class="mb-4">{{ provider.duration }}</p>
                    <p class="mb-4">{{ provider.optionToRenew }}</p>
                    <p class="mb-4">{{ provider.payments }}</p>
                    <p class="mb-4">{{ provider.minAmount }}</p>
                    <a href="#rateComparisonForm"
                        class="block mt-4 bg-blue-600 text-center text-white hover:bg-blue-700 py-2 px-4 rounded transition duration-300 w-full">
                        {{ provider.cta }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RateProviders',
    data() {
        return {
            providers: [
                {
                    name: 'GIC PER ANNUM',
                    gicPerAnnum: '1 YEAR',
                    duration: 'NON REGISTERED',
                    optionToRenew: '',
                    payments: 'QUARTERLY PAYMENTS',
                    minAmount: 'MIN $25.000',
                    cta: 'ENQUIRE NOW'
                },
                {
                    name: 'GOVERNMENT BOND',
                    gicPerAnnum: '7.570% PER ANNUM',
                    duration: '1 YEAR',
                    optionToRenew: '(OPTION TO RENEW)',
                    payments: 'QUARTERLY PAYMENTS',
                    minAmount: 'MIN $50.000',
                    cta: 'ENQUIRE NOW'
                },
                {
                    name: 'TOP 5 BANK BOND',
                    gicPerAnnum: '8.370% PER ANNUM',
                    duration: '1 YEAR',
                    optionToRenew: '(OPTION TO RENEW)',
                    payments: 'QUARTERLY PAYMENTS',
                    minAmount: 'MIN $100.000',
                    cta: 'ENQUIRE NOW'
                }
            ]
        }
    }
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
