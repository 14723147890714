<template>
    <div class="container mx-auto px-4 my-12">
        <TermsAndConditionsComponent />
    </div>
</template>

<script>
import TermsAndConditionsComponent from '@/components/TermsAndConditions.vue';

export default {
    name: 'TermsAndConditionsView',
    components: {
        TermsAndConditionsComponent
    }
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
