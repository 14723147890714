import { createRouter, createWebHistory } from 'vue-router';
import TermsAndConditions from '@/views/TermsAndConditions.vue';
import HomePage from '@/views/HomePage.vue';
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue';

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyView
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes // Ensure this is the array from above
});

export default router;
