<template>
    <div class="relative text-white py-16 md:py-32 lg:py-40"
        :style="`background-image: url(${require('@/assets/golden-coins-stacking-flying-pink-piggy-save.jpg')}); background-size: cover; background-repeat: no-repeat; background-position: center;`">

        <!-- Blue Overlay -->
        <div class="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 opacity-75"></div>

        <div class="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10 text-center">
            <h1
                class="text-5xl md:text-6xl lg:text-7xl font-black mb-4 text-shadow text-shadow-lg transform hover:scale-105 transition-transform duration-300">
                <span class="text-yellow-400 block mb-2">Apply Today & Get</span>
                <span class="border-b-4 border-yellow-400 inline-block pb-1">Fixed Rate Finder's Award-Winning Investors
                    Guide</span>
            </h1>

            <h2
                class="text-3xl md:text-4xl font-extrabold mb-6 mt-4 tracking-wide text-yellow-300 text-shadow text-shadow-lg">
                "The Canadians' Guide To Fixed Interest Bonds"
            </h2>

            <!-- Benefits -->
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 text-lg font-semibold">
                <div
                    class="bg-blue-700 p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 relative overflow-hidden flex items-center justify-center space-x-4">
                    <font-awesome-icon icon="eye" class="h-6 w-6 text-white" />
                    <div class="text-center">
                        <div class="absolute top-0 left-0 bg-white w-1 h-full"></div>
                        View options to secure your future.
                    </div>
                </div>
                <div
                    class="bg-blue-700 p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 relative overflow-hidden flex items-center justify-center space-x-4">
                    <font-awesome-icon icon="balance-scale" class="h-6 w-6 text-white" />
                    <div class="text-center">
                        <div class="absolute top-0 left-0 bg-white w-1 h-full"></div>
                        Compare the Pros and Cons of each product.
                    </div>
                </div>
                <div
                    class="bg-blue-700 p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 relative overflow-hidden flex items-center justify-center space-x-4">
                    <font-awesome-icon icon="lightbulb" class="h-6 w-6 text-white" />
                    <div class="text-center">
                        <div class="absolute top-0 left-0 bg-white w-1 h-full"></div>
                        Dive into a well-researched guide by some of the finest minds in finance & economics.
                    </div>
                </div>
            </div>

            <!-- CTA Button -->
            <a href="#rateComparisonForm"
                class="bg-yellow-400 hover:bg-yellow-500 text-gray-800 py-2 sm:py-3 md:py-3 px-4 sm:px-8 md:px-8 rounded-full shadow-xl font-bold text-sm sm:text-lg md:text-xl transition duration-300 transform hover:scale-105">
                Grab Your Guide & Explore Now!
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroSection'
}
</script>

<style scoped>
.text-shadow-lg {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
