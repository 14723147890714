<template>
    <div class="bg-white py-20">
        <!-- Container -->
        <div
            class="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center h-full space-y-8 md:space-y-0 md:space-x-8 max-w-6xl">

            <!-- Left: Text content -->
            <div class="w-full md:w-1/2 text-center md:text-left">
                <h2 class="text-3xl font-extrabold mb-6 text-blue-600">
                    Unlock Superior Returns:
                </h2>
                <p class="text-xl mb-4">
                    Compare leading GICs, Government Bonds, and Term Deposits today with
                    <span class="text-blue-600">Fixed Rate Finder</span>.
                </p>
                <p class="text-2xl font-bold">
                    Yield up to <span class="text-blue-600">8.37% p.a.</span>
                </p>
            </div>

            <!-- Right: Image with Canadian Flag as Background inside the circle -->
            <div class="flex md:w-1/2 justify-center relative md:h-96 md:w-96">
                <!-- Canadian Flag with reduced opacity for a faded effect -->
                <div class="absolute inset-0 rounded-full"
                    :style="`background-image: url(${require('@/assets/ca-flag.jpeg')}); background-size: cover; background-repeat: no-repeat; background-position: center; opacity: 0.4;`">
                </div>
                <!-- Woman's Image on top -->
                <img src="@/assets/b4b7a410-gic-featured_108g08g07l07l00g00f028.png" alt="GIC Image"
                    class="h-full w-full object-cover object-top rounded-full shadow-lg z-10">
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'UnlockReturnsSection'
}
</script>

<style scoped>
/* Additional styles if needed, but mostly it will be handled by Tailwind classes */
</style>
