<template>
    <div
        class="bg-gradient-to-r from-blue-800 to-blue-900 text-white py-2 sm:py-4 px-4 sm:px-8 relative flex items-center justify-between shadow-lg">

        <!-- Main Logo - Positioned to the left -->
        <div class="flex-shrink-0 transform transition-transform hover:scale-105 z-10">
            <img src="@/assets/logo-placeholder.png" alt="Main Logo" class="h-8 sm:h-12 w-auto" />
        </div>

        <!-- Centered Logo - Positioned in the center -->
        <div class="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-0">
            <img src="@/assets/frf-logo.png" alt="FIXED-INTEREST.BOND Logo" class="h-10 sm:h-12 md:h-16 w-auto" />
        </div>

        <!-- CTA Button with Hover Effect - Hidden on smaller screens -->
        <div class="z-10 hidden md:block">
            <a href="#rateComparisonForm" v-if="showCTA"
                class="bg-gradient-to-r from-yellow-300 to-yellow-500 text-black px-4 sm:px-6 py-2 text-lg font-semibold rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300 transform">
                Get Your Award-Winning Guide Now!
            </a>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'AppHeader',
    data() {
        return {
            showCTA: true
        }
    }
}
</script>
  
<style scoped>
.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
