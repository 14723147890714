<template>
  <div class="font-sans antialiased text-center text-gray-700 bg-gradient-to-r from-blue-600 to-blue-400">
    <AppHeader />
    <router-view /> <!-- This is where the content of your routes will be injected -->
    <FooterSection />
  </div>
</template>

<script>
import AppHeader from './components/Header.vue';  // Change here
import FooterSection from './components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    AppHeader,  // Change here
    FooterSection
  }
}
</script>

<style>
/* No need for additional styles as they are handled by Tailwind classes */
</style>
